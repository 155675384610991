<template>
  <div v-loading.fullscreen.lock="loading">

    <el-row justify="space-between" align="middle">
      <el-col :span="6">
        <h3>Patient Therapies</h3>
      </el-col>
      <el-col :sm="20" :md="12" :lg="12">
        <el-select @change="getTherapyAppointments()" class="m-2" v-model="center_id" placeholder="Select Center"
                   size="large">
          <el-option
              v-for="item in centers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-col>
    </el-row>

    <el-row :gutter="20" align="middle">
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p class="demonstration">Name/Phone number</p>
        <el-input v-model="q" size="large"></el-input>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p class="demonstration">Start date</p>
        <el-date-picker
            v-model="start_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p class="demonstration">End date</p>
        <el-date-picker
            v-model="end_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="mt-40">
        <el-button type="primary" size="large" @click="getTherapyAppointments" round>Filter</el-button>
        <el-button type="danger" size="large" @click="clear" round>Clear</el-button>
      </el-col>
      <el-col :xs="4" :sm="4" :md="2" :lg="2" :xl="2" class="mt-40">
        <el-button type="warning" size="large" @click="getTherapyAppointments" circle>
          <font-awesome-icon icon="fa-solid fa-rotate-right"/>
        </el-button>
      </el-col>
    </el-row>
    <br>

    <!--  Mobile components  -->
    <div class="mobile">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" v-for="(item,index) in tableData" :key="index">
          <div class="bg-white custom-box-card box-card mt-5">
            <div class="card-header">
              <div class="d-flex flex-direction-row justify-content-between align-items-center">
            <span>
              {{ item.prescription_identifier }}

            </span>
                <div class="">
                  <el-dropdown>
                <span class="el-dropdown-link">
                  <el-button type="info" circle>
                    <el-icon><More/></el-icon>
                  </el-button>
                </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item v-if="item.appointment_status" @click="showAppointmentDrawar(item)">Book
                          Therapy Session
                        </el-dropdown-item>
                        <!--                        <el-dropdown-item @click="generateInvoice(item)">Invoice</el-dropdown-item>-->
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <hr>
            <div class="card-body">
              <div class="d-flex  justify-content-between">
                <div v-if="item.doctor">
                  <UserCard
                      :name="item.doctor.fullname"
                      :phone_number="item.doctor.phone_number"
                      :image="item.doctor.image"></UserCard>
                </div>
                <br>
                <div v-if="item.patient">
                  <UserCard :name="item.patient.fullname"
                            :phone_number="item.patient.phone_number"
                            :image="item.patient.image"></UserCard>
                </div>
                <br>
                <p class="mb-0 mt-0">Therapies</p>
                <ol v-if="item.therapies">
                  <li v-for="(therapy, key) in item.therapies" :key="key">{{ therapy.therapy_name }}</li>
                </ol>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- Desktop component -->
    <el-table :data="tableData" border class="desktop" style="width: 100%">
      <el-table-column label="Patient" width="200px">
        <template #default="scope">

          <UserCard v-if="scope.row.patient"
                    :name="scope.row.patient.fullname"
                    :phone_number="scope.row.patient.phone_number"
                    :image="scope.row.patient.image"></UserCard>
        </template>
      </el-table-column>

      <el-table-column label="Doctor" width="200px">
        <template #default="scope">
          <UserCard v-if="scope.row.doctor"
                    :name="scope.row.doctor.fullname"
                    :phone_number="scope.row.doctor.phone_number"
                    :image="scope.row.doctor.image"></UserCard>
        </template>
      </el-table-column>

      <el-table-column prop="prescription_identifier" label="Prescription No." width="180px">
      </el-table-column>

      <el-table-column label="Therapies" width="200px">
        <template #default="scope">
          <ol>
            <li v-for="(therapy, key) in scope.row.therapies" :key="key">{{ therapy.therapy_name }} -
              {{ therapy.duration }} {{ therapy.duration_unit }}
            </li>
          </ol>
        </template>
      </el-table-column>

      <el-table-column label="Appointment Status" width="180px">
        <template #default="scope">

          <el-tag v-if="scope.row.appointment_status == 'Booked'" type="success">{{
              scope.row.appointment_status
            }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="actions" label="Actions" width="80px">
        <template #default="scope">
          <el-dropdown>
            <span class="el-dropdown-link">
          <el-button type="info" circle>
              <el-icon><More/></el-icon>
          </el-button>
          </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                    v-if="checkUsrPermissions('Book appointment') && authUser.sub_type != 'physio_assistant'"
                    @click="showAppointmentDrawar(scope.row)">Book
                  Therapy Session
                </el-dropdown-item>
                <el-dropdown-item
                    v-if="checkUsrPermissions('Book appointment') && authUser.sub_type != 'physio_assistant'"
                    @click="$router.push('/create/package/invoice?p='+scope.row.prescription_id+'&patient_id='+scope.row.patient.id+'&patient_name='+scope.row.patient.fullname)">
                  Generate Package Invoice
                </el-dropdown-item>

              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        v-model:current-page="currentPage"
        :page-size="per_page"
        layout="total, prev, pager, next"
        :total="total_count"
        @current-change="getTherapyAppointments"
    />

    <el-drawer
        v-model="drawer"
        title="Therapy Appointment"
        class="drawer-text custom-el-drawer"
        :direction="direction"
    >
      <el-tabs v-model="drawarTab" class="demo-tabs">
        <el-tab-pane label="Appointment" name="appointment">
          <!-- <el-form-item label="Appointment type">
            <el-radio-group v-model="form.service_type">
              <el-radio label="visit" @input="clickVisit"/>
              <el-radio label="virtual" @input="clickVirtual"/>
            </el-radio-group>
          </el-form-item> -->

          <el-row justify="center">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="new_patient_section d-flex justify-content-between ">
                <h3>Patient: {{ selectedPrescription.patient.fullname }}</h3>
              </div>
            </el-col>
          </el-row>
          <br/>
          <el-row>
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
              <div class="new_patient_section d-flex">
                <div class="w-100">
                  <el-select class="w-100" @change="selectDoctor" v-model="form.doctor_id" filterable clearable
                             placeholder="Select Therapy Assistant">
                    <el-option
                        v-for="item in assistants"
                        :key="item.id"
                        :label="item.username"
                        :value="item.id"
                    />
                  </el-select>
                </div>
              </div>
            </el-col>
          </el-row>
          <br/>
          <el-row v-if="form.doctor_id">
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
              <div class="d-flex justify-content-between">
                <div class="w-100">
                  <el-date-picker
                      class="app_date_picker w-100"
                      popper-class="my-datepicker"
                      v-model="form.date_value"
                      type="date"
                      placeholder="Pick a day"
                      :size="size"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      :disabled-date="disabledDate"
                      @change="clickDate"
                  />
                </div>
              </div>
            </el-col>
          </el-row>

          <el-row justify="center">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div v-if="chambers && form.date_value">
                <ChamberWithSlots @workPlaceId="workPlaceId" :chambers="chambers" v-model="form.slot_value"
                                  @slotNull="formDataNull"/>
              </div>
            </el-col>
          </el-row>
          <br>
          <div v-if="form.date_value">

            <br>
            <div class="text-center" v-if="form.doctor_id">
              <el-button @click="confirmAppointment" type="primary" round>Confirm Appointment</el-button>
            </div>
          </div>
        </el-tab-pane>

      </el-tabs>
    </el-drawer>

    <GenerateInvoiceModal v-if="generateInvoiceDialog" :invoice_details="invoice_details"
                          @closeModal="generateInvoiceDialog=false"/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import { CenterMixins } from '@/mixins/CenterMixins';
import UserCard from '../../components/core/UserCard';
import GenerateInvoiceModal from '../../components/modal/InvoiceGenerate';
import ChamberWithSlots from '../../components/ChamberWithSlots';

export default {
  name: 'Therapy',
  components: {
    UserCard,
    ChamberWithSlots,
    GenerateInvoiceModal,
  },
  mixins: [CenterMixins],
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      drawarTab: 'appointment',
      selectedPrescription: {},
      tableData: [],
      loading: false,
      labelPosition: 'top',
      form: {
        date_value: '',
        service_type: 'visit',
        patient_id: '',
        slot_value: '',
        workplace_id: '',
        doctor_id: '',
        working_schedule_time_slot: '',
      },
      chambers: [],
      assistants: [],
      generateInvoiceDialog: false,
      invoice_details: {},
      session_amount: 1500,
      total_count: 0,
      currentPage: 0,
      per_page: 20,
      start_date: null,
      end_date: null,
      q: '',
    };
  },
  created() {
    this.getTherapyAppointments();
  },
  computed: {
    ...mapGetters('auth', ['authUser']),

    subtotal() {
      return this.invoice_details.therapies.reduce((sum, item) => sum + this.session_amount, 0);
    },
    total() {
      return this.getDiscount(this.subtotal, this.invoice.discount);
    },
  },
  methods: {
    getDiscount(total, discountPercent) {
      const net_total = total * (discountPercent / 100);
      this.total = total - net_total;
      return net_total;
    },
    getTherapyAppointments(val) {
      if (val) {
        const offset = val - 1;
        var correctValue = offset * this.per_page;
      }
      this.loading = true;
      const url = `/api/v2/partner/agent/prescriptions/therapies?limit=${this.per_page}&offset=${correctValue || 0}${this.start_date ? `&start_date=${this.start_date}` : ''}${this.end_date ? `&end_date=${this.end_date}` : ''}${this.q ? `&q=${this.q}` : ''}${this.center_id ? `&institute_id=${this.center_id}` : ''}`;
      axios.get(url)
        .then((response) => {
          this.tableData = response.data.data;
          this.total_count = response.data.total_count;
          this.loading = false;
        });
    },
    clear() {
      this.search_type = '';
      this.search = '';
      this.schedule_date = '';
      this.start_date = '';
      this.end_date = '';
      this.getTherapyAppointments();
    },
    showAppointmentDrawar(prescription) {
      this.selectedPrescription = prescription;
      this.form.patient_id = prescription.patient_id;
      this.drawer = true;
      this.form.doctor_id = '';
      this.getPhysioAssistants();
    },
    addMinutes(time, minsToAdd) {
      function D(J) {
        return (J < 10 ? '0' : '') + J;
      }
      const piece = time.split(':');
      const mins = piece[0] * 60 + +piece[1] + +minsToAdd;

      return `${D(mins % (24 * 60) / 60 | 0)}:${D(mins % 60)}`;
    },
    clickDate() {
      this.chambers = [];
      this.loading = true;

      const url = `/api/v1/doctor/chamber/list?schedule_date=${this.form.date_value}&doctor_id=${this.form.doctor_id}${this.form.service_type == 'visit' ? '' : '&type=telemedicine'}`;
      axios
        .get(url)
        .then((res) => {
          this.chambers = res.data.data;
          this.loading = false;
        });
    },
    getPhysioAssistants() {
      this.loading = true;
      axios.get('/api/v2/doctor/center-wise/list?sub_type=physio_assistant&appointment=yes')
        .then((response) => {
          this.assistants = response.data.data;
          this.loading = false;
        });
    },
    disabledDate(time) {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      return time.getTime() < date;
    },
    workPlaceId(item) {
      this.form.workplace_id = item.id;
      this.form.working_schedule_time_slot = item.working_schedules[0].time_per_slot;
    },
    confirmAppointment() {
      this.loading = true;
      const form = new FormData();
      form.append('schedule_date', this.form.date_value);
      form.append('schedule_started_at', this.form.slot_value);
      form.append('workplace_id', this.form.workplace_id);
      form.append('doctor_id', this.form.doctor_id);
      form.append('schedule_end_at', this.addMinutes(this.form.slot_value, this.form.working_schedule_time_slot));
      form.append('type', 'appointment_booking_request_sent');
      form.append('appointment_type', 'therapy_session');
      form.append('appointment_status', 'booked');
      form.append('payable_amount', this.selectedPrescription.therapy_session_fee);
      form.append('prescription_id', this.selectedPrescription.prescription_id);
      form.append('service_type', 'visit');
      form.append('patient_id', this.form.patient_id);
      form.append('api_version', 'v2');
      let url = '';
      if (this.$route.query.appointment_type == 'Reschedule') {
        url = `${process.env.VUE_APP_URL}/api/v2/appointment/reschedule`;
      } else {
        url = `${process.env.VUE_APP_URL}/api/v2/appointment/book`;
      }
      axios
        .post(url, form)
        .then((res) => {
          this.loading = false;
          if (res.data.status_code == 200) {
            this.form = {
              service_type: 'visit',
            };
            this.$router.push('/my/appointments');
            this.$notify({
              title: 'Success',
              message: 'Successfully Appointment created',
              type: 'success',
              duration: 2000,
            });
          } else {
            this.$notify({
              title: 'Failed',
              message: 'Appointment Failed',
              type: 'danger',
              duration: 2000,
            });
          }
        });
    },
    invoiceGenerate(item) {
      this.invoice_details = item;
      this.generateInvoiceDialog = true;
    },
    todayDate() {
      let today = new Date();
      const dd = String(today.getDate())
        .padStart(2, '0');
      const mm = String(today.getMonth() + 1)
        .padStart(2, '0'); // January is 0!
      const yyyy = today.getFullYear();
      today = `${yyyy}-${mm}-${dd}`;
      return today;
    },
    selectDoctor() {
      this.form.date_value = this.todayDate();
      if (this.form.date_value && this.form.doctor_id) {
        this.clickDate();
      }
    },
    formDataNull() {
      this.form.slot_value = '';
    },
  },
  title: 'Therapy Prescription',
};
</script>

<style scoped>
h2, h3, h4, p {
  margin: 10px 0px;
  padding: 0;
}

.dashed {
  border: 2px dashed var(--el-border-color);
  padding: 0px 10px 30px 10px;
}

.single_patient {
  border: 1px solid #ddd;
  border-radius: 7px;
  padding: 20px;
  margin: 10px 0px;
}

</style>
<style>
.w-100 {
  width: 100% !important;
}
</style>
